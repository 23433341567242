import React, { createContext, useState } from "react";
import "./App.scss";
import Form from "./components/Form/Form";
import { Scan } from "./components/Scan/Scan";
import { Test } from "./components/Scan/Test";
import { ScannerIframe } from "./components/Scan/ScannerIframe";
import { ScanRes } from "./components/Scan/ScanRes";

import { BrowserRouter, Routes, Route } from "react-router-dom";

export const context = createContext();

const App = () => {
  const [data, setData] = useState({
    INSTORE_NAME_KEY: 'r',
    CONTEXT_DATA_KEY: 'contextDataKey'
  })
  
  return (
    <div className="App">
      <context.Provider value={ {data, setData} }>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Form mode='offline' />} />
            <Route path="/widget" element={<Form />} />
            <Route path="/scan" element={<Scan />} />
            <Route path="/scanner" element={<ScannerIframe />} />
            <Route path="/result" element={<ScanRes />} />
            <Route path="/test" element={<Test />} />
          </Routes>
        </BrowserRouter>
      </context.Provider>
    </div>
  );
};

export default App;
