import React, { useState,useEffect } from 'react';
import './SwitchButtonBigCircle.css';
import SwitchButton from '../Base/SwitchButton';

function SwitchButtonBigCircle({setState ,state=null, children}){    
    return(          
        <div className='switch-big-circle'>           
            <SwitchButton setState={setState} state={state}>
                {children}
            </SwitchButton>
        </div>
    )    
}
export default SwitchButtonBigCircle;
