import {  useEffect, useState } from "react";
import './PopupModal.scss';

export const PopupModal = ({ show={show:false}, okCB, cancelCB, title, children }) => {
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        setIsShow(show.show)
    }, [show])

    return (
        <>
            {
                isShow &&
                <div className={`modal`}>
                    <div className="screen"></div>
                    <div className="container">
                        <div className="content">
                            {title && <h3>{title}</h3>}
                            {children}
                        </div>
                        <div className="btns">
                            {okCB && <button className="ok-btn" onClick={() => { setIsShow(!isShow); okCB(); }}>OK</button>}
                            {cancelCB && <button className="cancel-btn" onClick={() => { setIsShow(!isShow); cancelCB();}}>Scan again</button>}
                        </div>
                    </div>
                </div>
            }
        </>)
}