module.exports = {
    REACT_APP_X_ADMIN_API_TOKEN: 'kofkof12',
    REACT_APP_IFRAME_URL: 'https://360olga.s3.eu-west-1.amazonaws.com/index.html',
    staging: {
        API_KEY: 'AIzaSyAAu3emEhazek1O54_7jrwv16Q7jLqCBeg',
        AUTH_DOMAIN: 'mysizeid-retailers-staging.firebaseapp.com',
        DATABASE_URL: 'https://mysizeid-retailers-staging.firebaseio.com',
        PROJECT_ID: 'mysizeid-retailers-staging',
        STORAGE_BUCKET: 'mysizeid-retailers-staging.appspot.com',
        MESSAGING_SENDER_ID: 651206021906,
        APP_ID: '1:651206021906:web:c8a83efdcb08895f31d9cb',
        MEASUREMENT_ID: 'G-45VC78K9PE',
        REACT_APP_API_BASE_URL: 'https://api-staging.mysz.io',
        BACKEND_BASE_URL: `https://api-staging.mysz.io/api/v1`,
        REACT_APP_API_BASE_URL_SERVERLESS:  'https://api2-staging.mysz.io/api/v2/'
    },
    dev: {
        API_KEY: 'AIzaSyAAu3emEhazek1O54_7jrwv16Q7jLqCBeg',
        AUTH_DOMAIN: 'mysizeid-retailers-staging.firebaseapp.com',
        DATABASE_URL: 'https://mysizeid-retailers-staging.firebaseio.com',
        PROJECT_ID: 'mysizeid-retailers-staging',
        STORAGE_BUCKET: 'mysizeid-retailers-staging.appspot.com',
        MESSAGING_SENDER_ID: 651206021906,
        APP_ID: '1:651206021906:web:c8a83efdcb08895f31d9cb',
        MEASUREMENT_ID: 'G-45VC78K9PE',
        REACT_APP_API_BASE_URL: 'http://localhost:5000',
        BACKEND_BASE_URL: `http://localhost:5000/api/v1`,
        REACT_APP_API_BASE_URL_SERVERLESS:  'https://api2-staging.mysz.io/api/v2/'
    },
    production: {
        API_KEY: "AIzaSyAteZy_YZ36ybvro-fA5sOhpnHPnP4IDMo",
        AUTH_DOMAIN: "auth.mysz.io",
        DATABASE_URL: "https://mysizeid-retailers.firebaseio.com",
        PROJECT_ID: "mysizeid-retailers",
        STORAGE_BUCKET: "mysizeid-retailers.appspot.com",
        MESSAGING_SENDER_ID: 298488817318,
        APP_ID: '1:651206021906:web:c8a83efdcb08895f31d9cb',
        MEASUREMENT_ID: 'G-45VC78K9PE',
        REACT_APP_API_BASE_URL: 'https://api.mysz.io',
        BACKEND_BASE_URL: `https://api.mysz.io/api/v1`,
        REACT_APP_ALLOWED_FORMATS: '14,15,7,6,4,2,8,12',
        REACT_APP_API_BASE_URL_SERVERLESS:  'https://api2.mysz.io/api/v2/'
    }
}