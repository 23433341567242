import React from 'react';
import './SwitchButtonSmallCircle.css';
import SwitchButton from '../Base/SwitchButton';


function SwitchButtonSmallCircle({setState ,state=null,children, rendererComponent}){    
    return(          
        <div className='switch-small-circle'>           
            <SwitchButton setState={setState} state={state} rendererComponent={rendererComponent}>
                {children}
            </SwitchButton>
        </div>
    )    
}
export default SwitchButtonSmallCircle;
