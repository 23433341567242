
function sessionSave(key, item) {
    sessionStorage.setItem(key, item)
}

function localSave(key, item) {
    localStorage.setItem(key, JSON.stringify(item))
}

function sessionLoad(key) {
    const value = sessionStorage.getItem(key)
    if (!value) return;
    return typeof value === 'object' ? JSON.parse(value) : value
}

function localLoad(key) {
    const value = localStorage.getItem(key)
    if (!value) return;
    return typeof value === 'object' ? JSON.parse(value) : value
}

function sessionRemove(key) {
    sessionStorage.removeItem(key)
}

function localRemove(key) {
    localStorage.removeItem(key)
}

function sessionClear() {
    sessionStorage.clear()
}

function localClear() {
    localStorage.clear()
}

const StorageService = {
    sessionSave,
    sessionLoad,
    sessionRemove,
    sessionClear,
    localSave,
    localLoad,
    localRemove,
    localClear
}

export default StorageService