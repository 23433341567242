import React, { useState, useEffect } from "react";
import SwitchButtonSmallCircle from "../SwitchButtons/SwitchButtonSmallCircle/SwitchButtonSmallCircle";
import PAGE_STATES from "../../utils/stateNames";
import "./Visualisation.scss";
import startOverIcon from "../../assets/images/start-over-icon.svg";
import config from '../../config';

export default function Visualization({
  size,
  body_type,
  gender,
  setPageState,
}) {
  const [inputSize, setInputSize] = useState(null);
  const [sizesArray, setSizesArray] = useState([]);

  useEffect(() => {
    switch (size) {
      case "XS":
        setSizesArray(["XS", "S"]);
        setInputSize(0);
        break;
      case "S":
        if (gender === 0) {
          setSizesArray(["S", "M"]);
          setInputSize(0);
        } else {
          setSizesArray(["XS", "S", "M"]);
          setInputSize(1);
        }
        break;
      case "M":
        setSizesArray(["S", "M", "L"]);
        setInputSize(1);
        break;
      case "L":
        setSizesArray(["M", "L", "XL"]);
        setInputSize(1);
        break;
      case "XL":
        if (gender === 0) {
          setSizesArray(["L", "XL", "XXL"]);
        } else {
          setSizesArray(["L", "XL"]);
        }
        setInputSize(1);
        break;
      case "XXL":
        setSizesArray(["XL", "XXL"]);
        setInputSize(1);
        break;
      default:
        break;
    }
  }, []);

  if (size === undefined) {
    return (
      <div className="no-size-container">
        <div className="title">No Matching Size</div>
        <div
          className="start-over-container"
          onClick={() => setPageState(PAGE_STATES.BASIC_INFO_PAGE)}
        >
          <img src={startOverIcon} alt="start over"></img>
          <p className="text">Start Over</p>
        </div>
      </div>
    );
  }

  const iframeLink =
    sizesArray[inputSize] &&
    `${config.REACT_APP_IFRAME_URL}?src=${
      gender === 0 ? "male" : "female"
    }/${parseInt(body_type)}/${size.toLocaleLowerCase()}/${sizesArray[
      inputSize
    ].toLocaleLowerCase()}`;


  return (
    <div className="visualization">
      <div className="left-section">
        <div className="title-section">
          <h1>Your Size Is: {size} </h1>
          <SwitchButtonSmallCircle
            state={inputSize}
            setState={setInputSize}
            rendererComponent={PAGE_STATES.VISUALISATION_PAGE}
          >
            {sizesArray}
          </SwitchButtonSmallCircle>
        </div>
      </div>
      <iframe src={iframeLink} title="3d visualzator"></iframe>

      <div
        className="start-over-container"
        onClick={() => setPageState(PAGE_STATES.BASIC_INFO_PAGE)}
      >
        <img src={startOverIcon} alt="start over"></img>
        <p className="text">Start Over</p>
      </div>
    </div>
  );
}
