import React from 'react'
import MultiResultProduct from '../MultiResultProduct/MultiResultProduct'
import './MultiResultCategory.scss'

export default function MultiResultCategory({categories}) {
    return (
        <>
            {categories && categories.map((category, index) => {
                return (
                    <div key={'list'+index} className="category-row">
                        <div className="product-item category-name">{category.name}</div>
                        <MultiResultProduct products={category.products}/>
                    </div>
                )
            })}
        </>
    )
}
