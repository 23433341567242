
const euBandArr = [
    "Band",
    "65",
    "70",
    "75",
    "80",
    "85",
    "90",
    "95",
    "100",
    "105",
    "110",
  ];
  const usBandArr = [
    "Band",
    "30",
    "32",
    "34",
    "36",
    "38",
    "40",
    "42",
    "44",
    "46",
    "48",
  ];
  const usCupArr = [
    "Cup",
    "AA",
    "A",
    "B",
    "C",
    "D",
    "DD",
    "DDD",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
  ];
  const euCupArr = [
    "Cup",
    "AA",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
  ];

  export{ euBandArr, usBandArr, usCupArr, euCupArr }