import './Loader.scss';
import RedoIcon from '../../assets/images/loader.svg';

export const Loader = () => {
    return (
        <section className="loader">
            <img src={RedoIcon} alt="Loading Icon"/>
            Loading...
        </section>
    )
}