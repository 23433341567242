import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { context } from '../../App';
import MySizeApiService from "../../services/MySizeApiService";
import Logo from '../../assets/images/size-new-logo.png';
import ArrowLeft from '../../assets/images/arrow-left-gray.svg';
// import Redo from '../../assets/images/redo-icon-gray.svg';
import { Loader } from '../Loader/Loader';
import ColorTag from '../../assets/images/color-tag.svg';
import { PopupModal } from '../Popup/PopupModal';
import BarcodeIcon from '../../assets/images/barcode-icon.svg';
import { jsonToFormData, apiMeasuremenets } from '../../utils/api-measurements';
import StorageService from '../../services/StorageService';

let size;
let productImage;


export const ScanRes = () => {
    const userContext = useContext(context);
    let productBCD = userContext.barcode?.data;
    const userToken = userContext.data?.user?.token;
    const productApiLink = `https://api.mysz.io/api/v1/instore/users/me/garment_size?barcode=${productBCD}&instore_name=${userContext?.data?.meta?.instore_auth_code}`;
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [size, setSize] = useState(null)

    let navigate = useNavigate();


    useEffect(() => {
        console.log('product bcd:', productBCD, '\n\nsize:', size, '\n\nimage:', productImage, '\n\nuserContext:', userContext);
        // if (!userToken) navigate('/');
        userContext.origin = '/result';
        if (!productBCD){
            lastBarcodeToCurrent();
            setIsLoading(false)
        } else 
        getFromApi()
    }, [])
    const lastBarcodeToCurrent = () => {
        productBCD = userContext.lastBarcode?.productBCD;
        size = userContext.lastBarcode.size;
        productImage = userContext.lastBarcode.productImage;
    }
    const getFromApi = async () => {
        const product = await MySizeApiService.getBarcodeProduct(userContext?.data?.meta?.instore_auth_code, productBCD);
        if (product.code > 400) {
            if (product.code === 404) {
                if (userContext.action === 'back') {
                    lastBarcodeToCurrent();
                    userContext.action = null;
                }  
                setIsError(true)
            }
        } else {
            // size = userInfo.size;
            // productImage = userInfo.image_url;
            const haveBodyShapes = !StorageService.sessionLoad(userContext.data?.INSTORE_NAME_KEY) || userContext.data?.meta?.instore_show_body_shapes;  

            const garmetSizeParams = {
                measurement_system: userContext.data.user.userInputs.measurementSystem === 0 ? "imperial" : "metric",
                retailer_token: userContext.data.meta.shop_id,
                gender: userContext.data.user.userInputs.gender ? "male" : "female",
                measurements: apiMeasuremenets(userContext.data.user.userInputs, true, haveBodyShapes),
                size_chart_code: product.size_chart_code,
              }
            let garmentSize = await MySizeApiService.getGarmentSizeServerless(new URLSearchParams(jsonToFormData(garmetSizeParams)))
            garmentSize = await garmentSize.json();
            setSize(garmentSize.size);
            userContext.lastBarcode = { productBCD, size: garmentSize.size, productImage };
        }
        setIsLoading(false)
    }

    const onBack = () => { 
        navigate('/scan') 
    }

    return (
        <section className="scan-result">
            {isLoading && <Loader />}
            {isError &&
                <PopupModal
                    show={{ show: true }}
                    okCB={() => {
                        if (Object.keys(userContext.lastBarcode).length>0) {
                            lastBarcodeToCurrent()

                            setIsError(false)
                        } else {
                            navigate('/scan')
                        }
                    }}>
                    Item not found
                </PopupModal>
            }
            {!isError && !isLoading &&
                <>
                    <img className="logo" src={Logo} alt="Logo" />
                    <div className="container">
                        <button className="back" onClick={onBack}><img src={ArrowLeft} alt="Back" /></button>
                        <div>
                            <h6>{size ? `Your Size is: ${size}` : 'No matching size'}</h6>
                        </div>
                        <img className="product-image" src={productImage ? productImage : ColorTag} alt="Product" onLoad={() => { setIsLoading(false) }} />
                    </div>
                    <button className="scan-again" onClick={() => { navigate('/scanner') }}><img src={BarcodeIcon} alt="Barcode" />SCAN ANOTHER ITEM</button>
                </>}


        </section>
    )
}