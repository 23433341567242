import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { context } from "../../App";
import "./Form.scss";
import MySizeApiService from "../../services/MySizeApiService";
import BasicInfo from "../BasicInfo/BasicInfo";
import PAGE_STATES from "../../utils/stateNames.js";
import AdvanceInfo from "../AdvanceInfo/AdvanceInfo";
import Visualisation from "../Visualisation/Visualisation";
import leftArrowIcon from "../../assets/images/arrow-left.svg";
import { useSearchParams } from "react-router-dom";
import StorageService from '../../services/StorageService'
import FirebaseService from "../../services/FirebaseService";
import { Scan } from "../Scan/Scan";
import { Loader } from "../Loader/Loader";
import { PopupModal } from "../Popup/PopupModal";

function Form({ mode = 'online' }) {
  const userContext = useContext(context);
  let navigate = useNavigate();

  const [gender, setGender] = useState(0);
  const [radioBtn, setRadioBtn] = useState(0);
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [measurementSystem, setMeasurementSystem] = useState(0);
  const [bellyShape, setBellyShape] = useState(null);
  const [hipShape, setHipShape] = useState(null);
  const [braUnit, setBraUnit] = useState(0);
  const [braBand, setbBraBand] = useState(0);
  const [braCup, setbBraCup] = useState(0);
  const [feet, setFeet] = useState(0);
  const [inches, setInches] = useState(0);
  const [heightValid, setHeightValid] = useState(false);
  const [weightValid, setWeightValid] = useState(false);
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [pageState, setPageState] = useState(PAGE_STATES.BASIC_INFO_PAGE);
  const [size, setSize] = useState(null);
  const [bodyType, setBodyType] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [email, setEmail] = useState("")
  const [showScan, setShowScan] = useState(false);
  
  const [searchParams] = useSearchParams();


  const emailPattern = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  useEffect(() => {
    async function setMeta() {
      userContext.setData({
        ...userContext.data,
        meta: await getMetaData()
      })
    }

    new FirebaseService().signOutUser();

    if (searchParams.get(userContext.data?.INSTORE_NAME_KEY)) {
      StorageService.sessionSave(userContext.data?.INSTORE_NAME_KEY, searchParams.get(userContext.data?.INSTORE_NAME_KEY))
      setMeta()
    } else {
      StorageService.sessionRemove(userContext.data?.INSTORE_NAME_KEY)
      StorageService.sessionRemove(userContext.data.CONTEXT_DATA_KEY)
    }
  }, [])

  useEffect(() => {
    setMeasurementSystem(userContext?.data?.meta?.instore_measurement_system === 'metric' ? 1 : 0)
  }, [userContext.data])

  const getMetaData = async () => {
    return new Promise(async resolve => {
      const meta = await MySizeApiService.getInstoreMetaData(searchParams.get(userContext.data.INSTORE_NAME_KEY))
      StorageService.sessionSave(userContext.data.CONTEXT_DATA_KEY, JSON.stringify({ data: { ...userContext.data, meta } }));
      resolve(meta)
    })
  }

  useEffect(() => {
    setIsDirty({
      ...isDirty,
      email: email !== ''
    })
  }, [email])

  useEffect(() => {
    // imprial
    if (measurementSystem === 0) {
      metricToImperial();
      setIsDirty({
        ...isDirty,
        feet: feet ? true : false,
        inch: inches ? true : false,
        height: false,
      });
      // metric
    } else {
      imperialToMetric();
      setIsDirty({
        ...isDirty,
        feet: false,
        inch: false,
        height: height ? true : false,
      });
    }
  }, [measurementSystem]);

  useEffect(() => {
    setIsDirty({
      ...isDirty,
      height: !height || +height < 31 ? false : true,
    });
  }, [height]);
  
  useEffect(() => {
    setIsDirty({
      ...isDirty,
      weight: !weight || +weight === 0 ? false : true,
    });
  }, [weight]);

  useEffect(() => {
    if (+feet === 7 && +inches === 12) 
      setInches(11);
    setIsDirty({
      ...isDirty,
      feet: +feet !== 0 ? true : false,
      inch: +inches !== 0 ? true : false,
    });
  }, [feet, inches]);

  useEffect(() => {
    setIsDirty({
      ...isDirty,
      band: radioBtn === 1 && +braBand > 0 ? true : false,
      cup: radioBtn === 1 && +braCup > 0 ? true : false,
    });
    setErrors({
      ...errors,
      band: radioBtn === 1 ? true : false,
      cup: radioBtn === 1 ? true : false,
      msg: radioBtn === 0 ? "" : errors.msg,
    });
  }, [radioBtn]);

  useEffect(() => {
    setIsDirty({
      ...isDirty,
      band: +braBand > 0 ? true : false,
      cup: +braCup > 0 ? true : false,
    });
  }, [braBand, braCup]);

  useEffect(() => {
    switch (pageState) {
      case PAGE_STATES.BASIC_INFO_PAGE:
        if (isDirty.lastStepVisited > 1) break;
        setIsDirty({
          ...isDirty,
          feet: false,
          inch: false,
          height: false,
          weight: false,
          lastStepVisited: 1,
        });
        setErrors({ ...errors, height: false, weight: false });
        break;
      case PAGE_STATES.ADVANCED_INFO_PAGE:
        if (isDirty.lastStepVisited > 2) break;
        setIsDirty({
          ...isDirty,
          band: +braBand > 0 ? true : false,
          cup: +braCup > 0 ? true : false,
          lastStepVisited: 2,
        });
        setErrors({ ...errors, band: false, cup: false });
        break;
      case PAGE_STATES.VISUALISATION_PAGE:
        if (isDirty.lastStepVisited > 3) break;
        setIsDirty({ ...isDirty, lastStepVisited: 3 });
        break;

      default:
        break;
    }
    setErrors({ ...errors, msg: "" });
  }, [pageState]);

  useEffect(() => {
    switch (pageState) {
      case PAGE_STATES.BASIC_INFO_PAGE:
        if (userContext.data?.meta?.instore_show_email) {
          if (!isDirty.email) {
            setErrors({ msg: "Please enter your email" });
            return;
          } else if (!emailPattern.test(email)) {
            setErrors({ msg: "Invalid email" });
            return;
          } else {
            setErrors({ msg: "" });
          }
        }

        console.log({isDirty});

        console.log({height, weight, feet,inches, measurementSystem});

        if (!email && userContext.data?.meta?.instore_show_email) {
          setErrors({ msg: "Please enter your email" });
          return;
        }

        // METRIC
        if(measurementSystem === 1) {
          
          if(height !== 0 && !(height <= 220 && height >= 40)){
            setErrors({ msg: "Height is out of range" });
            return;
          }
          if(measurementSystem === 1 && weight !== 0 && !(weight <= 300)){
            setErrors({ msg: "Weight is out of range" });
            return;
          }
          if(height === 0 || height === null || weight === 0 || weight === null ){
            setErrors({ msg: "Please set your height and weight" });
            return;
          }
        }
        
        // IMPERIAL
        if(measurementSystem === 0) {

          if(feet !== 0 && !(feet * 12 + +inches <= 87 && feet * 12 + +inches >= 16)){
            setErrors({ msg: "Height is out of range" });
            return;
          }
          if(weight !== 0 && !(weight <= 662)){
            setErrors({ msg: "Weight is out of range" });
            return;
          }
          if(feet === 0 || feet === null || weight === 0 || weight === null ){
            setErrors({ msg: "Please set your height and weight" });
            return;
          }
        }

        // if (!isDirty.feet && !isDirty.height && !isDirty.weight) {
        //   setErrors({ msg: "Please set your height and weight" });
        //   return;
        // } else {
        //   setErrors({ msg: "" });
        // }

        // if (!isDirty.feet && !isDirty.height) {
        //   setErrors({ msg: "Please set your height" });
        //   return;
        // } else {
        //   setErrors({ msg: "" });
        // }
        // if (!isDirty.weight) {
        //   setErrors({ msg: "Please set your weight" });
        //   return;
        // }

        if(!isValidBmi()){
          setErrors({msg: "Invalid weight or height"}); 
        }
         else {
          setErrors({ msg: "" });
        }
        break;

      case PAGE_STATES.ADVANCED_INFO_PAGE:
        if (radioBtn === 1 && (!isDirty.band || !isDirty.cup)) {
          setErrors({
            msg: `Please select your ${!isDirty.band ? "band" : ""}${!isDirty.band && !isDirty.cup ? " and " : ""
              }${!isDirty.cup ? "cup" : ""} size`,
          });
          return;
        } else {
          setErrors({ msg: "" });
        }

        break;

      default:
        break;
    }
  }, [isDirty]);

  

  const showBodyShapes = () => !StorageService.sessionLoad(userContext.data?.INSTORE_NAME_KEY) || userContext.data?.meta?.instore_show_body_shapes

  const onSubmitHandler = async () => {
    // form validation
    switch (pageState) {
      case 1:
        if (
          (measurementSystem === 0 && (feet < 1 || Number.isInteger(feet))) ||
          (measurementSystem === 1 && height < 20)
        ) {
          setErrors({ ...errors, height: true });
          return;
        }
        break;

      default:
        break;
    }

    const femaleWithoutBraSize = gender === 1 && (braCup || braBand) === 0;

    const bodyShapesMeasurements = [
      {
        measurement_type_name: "bra_band",
        value: femaleWithoutBraSize
          ? "999"
          : gender === 0
            ? 0
            : (braBand - 1) * 5 + 65,
      },
      {
        measurement_type_name: "bra_cup",
        value: femaleWithoutBraSize ? "999" : braCup,
      },
      {
        measurement_type_name: "bra_unit",
        value: femaleWithoutBraSize ? "999" : braUnit,
      },
      { measurement_type_name: "belly_shape", value: bellyShape + 1 },
      {
        measurement_type_name: "hip_shape",
        value: gender === 1 ? hipShape + 1 : 0,
      }
    ]

    const measurements = [
      {
        measurement_type_name: "height",
        value: measurementSystem === 0 ? feet * 30.48 + inches * 2.45 : height,
      },
      {
        measurement_type_name: "weight",
        value: measurementSystem === 0 ? Math.round(weight / 2.20462) : Math.round(weight),
      }
    ];
    
    if (showBodyShapes()) {
      measurements.push(...bodyShapesMeasurements)
    }

    setShowLoader(true);

    let garment_size = {};
    let body_type = {};    
    let user = {}

    // if (!userContext.data.meta?.instore_multi_product_mode && (StorageService.sessionLoad(userContext.data?.INSTORE_NAME_KEY) || mode === 'online')) {
    //   garment_size = await MySizeApiService.getGarmentSize(
    //     user.external_id,
    //     userContext.data?.meta?.instore_auth_code
    //   );

    //   body_type = await MySizeApiService.getBodyType(
    //     user.external_id,
    //     userContext.data?.meta?.instore_auth_code
    //   );
    // }
    user.userInputs = {
      measurements: {
        height,
        weight,
        hipShape,
        bellyShape,
        braUnit,
        braBand,
        braCup,
        feet,
        inches
      },
      gender,
      measurementSystem,
      email
    }
    


    StorageService.sessionSave(userContext.data.CONTEXT_DATA_KEY, JSON.stringify({
      data: {
        ...userContext.data, 
        user, 
        garment_size, 
        // body_type
      }
    }))

    userContext.setData({
      ...userContext.data, 
      user, 
      garment_size, 
      // body_type
    });

    // console.log("after calls => ", user, garment_size, body_type);
    setSize(garment_size?.name);
    // if (showBodyShapes()) {
    //   setBodyType(body_type?.value);
    // }

    setShowLoader(false);
    setPageState(PAGE_STATES.MULTI_SIZES_PAGE);
  };

  const imperialToMetric = () => {
    const cm = feet * 30.48 + inches * 2.45;
    const kg = weight * 0.453592;
    setHeight(Math.round(cm));
    setWeight(kg);
  };

  const metricToImperial = () => {
    const inches = height * 0.393701;
    const newFeet = Math.floor(inches / 12);
    const newInches = inches - newFeet * 12;
    const newWeight = weight * 2.20462;

    if(+inches > 0.1) setInches(Math.round(newInches));
    setFeet(newFeet);

    setWeight(newWeight);
  };

  const backClickHandler = () => {
    switch (pageState) {
      case PAGE_STATES.ADVANCED_INFO_PAGE:
        setPageState(PAGE_STATES.BASIC_INFO_PAGE);
        break;
      case PAGE_STATES.VISUALISATION_PAGE:
        if (showBodyShapes())
          setPageState(PAGE_STATES.ADVANCED_INFO_PAGE);
        else
          setPageState(PAGE_STATES.BASIC_INFO_PAGE);
        break;
      default:
        break;
    }
  };


  const getOnlyInchesFromFeetAndInches = (feet, inches) => {
    return feet * 12 + inches
  }

  const calcBmi = ({ metricUnits, weight, height, feet, inches }) => {
    // metric: BMI = kg/((cm/100)^2)   imperial: 703*(lbs/(inches^2))
    switch (metricUnits) {
      case true:
        // metric
        return weight / (Math.pow(height / 100, 2));
      case false:
        // imperial
        const onlyInches = getOnlyInchesFromFeetAndInches(feet, inches)
        return 703 * (weight / Math.pow(onlyInches, 2))
      default:
        return null
    }
  }

  const resetForm = () => {
    setHeight(0);
    setWeight(0);
    setEmail("");
    setFeet(0);
    setInches(0);
    setBellyShape(null);
    setHipShape(null);
    setBraUnit(0);
    setbBraBand(0);
    setbBraCup(0);
    setRadioBtn(0)
    setGender(0)
    setMeasurementSystem(userContext.data?.meta?.instore_measurement_system === 'metric' ? 1 : 0);
  }

  const isValidBmi = () => {
    const MIN_BMI = 5;
    const MAX_BMI = 130;
    const bmi =  calcBmi({ metricUnits: !!(measurementSystem), weight: Number(weight), height: Number(height), feet: Number(feet), inches: Number(inches) });
    return bmi >= MIN_BMI && bmi <= MAX_BMI;
  }




  let page = null;
  switch (pageState) {
    case PAGE_STATES.BASIC_INFO_PAGE:
      page = (
        <BasicInfo
          measurementSystem={measurementSystem}
          setMeasurementSystem={setMeasurementSystem}
          feet={feet}
          setFeet={setFeet}
          inches={inches}
          setInches={setInches}
          height={height}
          setHeight={setHeight}
          weight={weight}
          setWeight={setWeight}
          gender={gender}
          setGender={setGender}
          weightValid={weightValid}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          heightValid={heightValid}
          errors={errors}
          showBodyShapes={showBodyShapes}
          setPageState={setPageState}
          onSubmitHandler={onSubmitHandler}
          showLoader={showLoader}
          setEmail={setEmail}
          email={email}
        />
      );
      break;

    case PAGE_STATES.ADVANCED_INFO_PAGE:
      page = (
        <AdvanceInfo
          setBellyShape={setBellyShape}
          bellyShape={bellyShape}
          gender={gender}
          hipShape={hipShape}
          setHipShape={setHipShape}
          radioBtn={radioBtn}
          setRadioBtn={setRadioBtn}
          braUnit={braUnit}
          setBraUnit={setBraUnit}
          braBand={braBand}
          setbBraBand={setbBraBand}
          braCup={braCup}
          setbBraCup={setbBraCup}
          errors={errors}
          setPageState={setPageState}
          onSubmitHandler={onSubmitHandler}
          showLoader={showLoader}
          showBodyShapes={showBodyShapes}
        />
      );
      break;

    case PAGE_STATES.VISUALISATION_PAGE:
      page = (
        <Visualisation
          size={size}
          body_type={bodyType}
          gender={gender}
          setPageState={setPageState}
        />
      );
      if (mode === 'offline') {
        navigate('/scan');
      }
      break;
    case PAGE_STATES.MULTI_SIZES_PAGE:
      page = (<Scan setPageState={setPageState} resetForm={resetForm}/>)
      break;
    default:
      break;
  }



  if(searchParams.get(userContext.data?.INSTORE_NAME_KEY) && userContext.data?.meta?.code === 404) return<PopupModal
      show={{ show: true }}
      okCB={() => navigate('/')}>
        Retailer not found
    </PopupModal>
  if(searchParams.get(userContext.data?.INSTORE_NAME_KEY) && !userContext.data?.meta?.instore_auth_code) return <Loader/>
  
  return (
    <div className="form">
      {pageState === PAGE_STATES.ADVANCED_INFO_PAGE && (
        <img
          className="left-arrow"
          onClick={backClickHandler}
          src={leftArrowIcon}
          alt="left arrow"
        ></img>
      )}
      {page}
    </div>
  );
}

export default Form;
