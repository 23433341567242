import React from 'react'
import './MultiResultProduct.scss'

export default function MultiResultProduct({products}) {
    return (
        <>
            {products?.map((product, index) => 
                <div key={'preview'+index} className="product-item">
                    <div className="product-name">{product.product_name}</div>
                    <div className="product-size">{product.size}</div>
                </div>
            )}
        </>
    )
}
