
import config from '../config'

const get = async (url, queryParams = null, customHeaders = null) => {
  try {
    let response = await fetch(
      url + mapQueryParams(queryParams),
      {
        headers: customHeaders ? customHeaders : getHeaders(),
      }
    );
    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw error;
  }
}

const pureGet = async (url, queryParams = null, customHeaders = null) => {
  try {
    let response = await fetch(
      url + mapQueryParams(queryParams),
      {
        headers: customHeaders ? customHeaders : getHeaders(),
      }
    );
    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw error;
  }
}

const post = async (url, body, queryParams = null) => {
  try {
    let response = await fetch(
      url + mapQueryParams(queryParams),
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      }
    );

    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw error;
  }
}

const purePost = async (url, body, queryParams = null) => {
  try {
    let response = await fetch(
      url + mapQueryParams(queryParams),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(body),
      }
    );

    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw error;
  }
}

const put = async (url, body, queryParams = null) => {
  try {
    let response = await fetch(
      url + mapQueryParams(queryParams),
      {
        method: "PUT",
        headers: getHeaders(),
        body: JSON.stringify(body),
      }
    );
    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw error;
  }
}

const remove = async (url, queryParams = null) => {
  try {
    let response = await fetch(
      url + mapQueryParams(queryParams),
      {
        method: "DELETE",
        headers: getHeaders(),
      }
    );
    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw error;
  }
}

const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Admin-Api-Token": config.REACT_APP_X_ADMIN_API_TOKEN,
  };
}

const mapQueryParams = (queryParams) => {
  return queryParams
    ? '?' + Object.keys(queryParams)
      .map(function (key) {
        return key + "=" + queryParams[key];
      })
      .join("&")
    : "";
}

const HttpService = {
  get,
  pureGet,
  post,
  purePost,
  put,
  remove,
  getHeaders
};

export default HttpService;