import HttpService from "./HttpService";
import FirebaseService from "./FirebaseService"
import generateAlphanumeric from "../utils/generateAlphanumeric";
import config from '../config';

const BASE_URL = `${config[process.env.REACT_APP_ENV].REACT_APP_API_BASE_URL}/api/v1/`;
const BASE_URL_SERVERLESS = `${config[process.env.REACT_APP_ENV].REACT_APP_API_BASE_URL_SERVERLESS}`;

const getExternalUser = async (instoreAuthCode, measurementSystem, gender, measurements, email) => {
  const REQUEST_URL = BASE_URL + "sdk/external_users/tesla"

  const body = {
    measurement_system: measurementSystem,
    gender: gender,
    measurements,
    create_if_not_found: true,
    external_id: `${Date.now().toString() + generateAlphanumeric(6)}${email ? `_${email}` : ""}`,
    ...(email ? {email} : {}),
    instore_auth_code: instoreAuthCode,
    sdk_key: "",
    sdk_secret: ""
  }

  let response = null;
  try {
    response = await HttpService.post(REQUEST_URL, body);
  } catch (error) {
    return error
  }
  return response;
}

const getGuestUser = async (measurementSystem, gender, email) => {
  const REQUEST_URL = BASE_URL + "users/widget"

  // create an anonymous user in firebase
  const firebaseUser = await new FirebaseService().signUpGuestUser();
  const body = {
    measurement_system: Math.abs(measurementSystem - 1) === 0 ? 'metric' : 'imperial',
    gender: gender === 0 ? 'male' : 'female',
    is_guest: true,
    auth_token: firebaseUser.token,
    ...(email ? {email} : {})
  }

  let response = null;
  try {
    response = await HttpService.post(REQUEST_URL, body);
    response.token = firebaseUser.token
  } catch (error) {
    return error
  }
  return response;
}

// used for updating a guest user after creation
const updateGuestUser = async (auth_token, measurementSystem, gender, height, weight) => {
  const REQUEST_URL = BASE_URL + "users/widget/me/measurements"

  const body = {
    measurement_system: Math.abs(measurementSystem - 1) === 0 ? 'metric' : 'imperial',
    gender: gender === 0 ? 'male' : 'female',
    isGuest: true,
    auth_token: auth_token,
    height_value: height,
    weight_value: weight
  }

  let response = null;
  try {
    response = await HttpService.post(REQUEST_URL, body);
    console.log(response);
  } catch (error) {
    console.log(error);
    return error
  }
  return response;
}

const getGarmentSize = async (externalId, instoreAuthCode, integrationCode) => {
  const REQUEST_URL = BASE_URL + "sdk/external_users/me/garment_size"

  let response = null;
  const params = {
    external_id: externalId,
    instore_auth_code: instoreAuthCode,
    integration_code: integrationCode,
    sdk_key: "",
    sdk_secret: ""
  }
  try {
    response = await HttpService.get(REQUEST_URL, params);
  } catch (error) {
    return error
  }
  return response;
}

const getBarcodeProduct = async (instoreName, productBarcode) => {
  const REQUEST_URL = BASE_URL + "instore/product"

  let response = null;
  const params = {
    instore_name: instoreName,
    product_barcode: productBarcode
  }
  try {
    response = await HttpService.get(REQUEST_URL, params);
  } catch (error) {
    return error
  }
  return response;
}

const getGarmentSizeServerless = async (body) => {
  const REQUEST_URL = BASE_URL_SERVERLESS + "widget/garment_size"

  let response = null;

  try {
    response = await fetch(REQUEST_URL, {
      body,
      method: 'POST',
      headers: {
        "Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    });
  } catch (error) {
    return error
  }
  return response;
}

const getBodyType = async (externalId, instoreAuthCode) => {
  const REQUEST_URL = BASE_URL + "sdk/external_users/me/measurement"

  let response = null;
  try {
    const params = {
      external_id: externalId,
      measurement_type_name: "body_type",
      instore_auth_code: instoreAuthCode,
      sdk_key: "",
      sdk_secret: ""
    }
    response = await HttpService.get(REQUEST_URL, params)
  } catch (error) {
    return error;
  }
  return response;
}

const getInstantAppShortLink = async (instantAppApiLink) => {
  const REQUEST_URL = "https://x.mysz.io/links"

  let response = null;
  const body = { 
    'url': instantAppApiLink 
  }
  try {
    response = await HttpService.purePost(REQUEST_URL, body)
  } catch (error) {
    return error;
  }
  return response;
}

const getInstoreMetaData = async (instoreName) => {
  const REQUEST_URL = BASE_URL + `instore/retailers/${instoreName}/meta`
  console.log('request url => ', REQUEST_URL)

  try {
    const response = await HttpService.get(REQUEST_URL)
    return response
  } catch (error) {
    throw error
  }
}

const getAllStoreCategories = async (gender, instoreName, instoreAuthCode) => {
  const REQUEST_URL = BASE_URL + 'sdk/external_users/me/multi_result_categories'
  
  const params = {
    gender,
    instore_name: instoreName,
    instore_auth_code: instoreAuthCode,
    sdk_key: "",
    sdk_secret: ""
  }

  try {
    const response = await HttpService.get(REQUEST_URL, params)
    return response
  } catch (error) {
    throw error
  }
}


const getProductInfo = async (endpoint, userToken) => {
  let response = null;
  try {
    response = await HttpService.get(endpoint, '', {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Auth-Token": userToken
    })
    return response;
  } catch (error) {
    throw error;
  }
}


const MySizeApiService = {
  getExternalUser,
  getGuestUser,
  updateGuestUser,
  getGarmentSize,
  getBodyType,
  getInstantAppShortLink,
  getInstoreMetaData,
  getAllStoreCategories,
  getProductInfo,
  getGarmentSizeServerless,
  getBarcodeProduct
}

export default MySizeApiService

// API CREATION EXAMPLES


// async disconnectSizeChartFromProduct(externalProductID) {
//   let response = await this.delete(
//     `/retailers/me/shop_products/${externalProductID}/pair`
//   );
//   return response;
// }

// async submmitSizeChart(sizeChartID, body) {
//   let response = await this.post(
//     `/retailers/me/size_charts/${sizeChartID}/approval_statuses`,
//     body
//   );
//   return response;
// }

// async connectSizeChartFromProduct(externalProductID, body) {
//   let response = await this.post(
//     `/retailers/me/shop_products/${externalProductID}/pair`,
//     body
//   );
//   return response;
// }

// async getAllStoreProdut(pageInfoParam) {
//   let response = await this.get(
//     `/retailers/me/shop_products${pageInfoParam}`
//   );
//   return response;
// }

// async getFilteredStoreProduct(pageInfoParam) {
//   let response = await this.get(
//     `/retailers/me/shop_products${pageInfoParam}`
//   );
//   return response;
// }

// async getFilterOptions() {
//   let response = await this.get(
//     `/retailers/me/shop_products/filter_options`
//   );
//   return response;
// }


// async getAllSizeCharts() {
//   let response = await this.get("/retailers/me/size_charts");
//   return response;
// }

// async deleteSelectedSizeChart(id) {
//   let response = await this.delete(`/retailers/me/size_charts/${id}`);
//   return response;
// }
// async addSizeChart(body) {
//   let response = await this.post(`/retailers/me/size_charts`, body);
//   return response;
// }

// async getPresignedUrlForUploadingFile(body) {
//   let response = await this.post(
//     `/retailers/me/size_chart_file_uploads`,
//     body
//   );
//   return response;
// }

// async updateSizeChartUploadFileStatus(body, id) {
//   let response = await this.post(
//     `/retailers/me/size_chart_file_uploads/${id}`,
//     body
//   );
//   return response;
// }

// async getSizeChartByID(id) {
//   let response = await this.get(`/retailers/me/size_charts/${id}`);
//   return response;
// }

// async updateSizeChartByID(id, body) {
//   let response = await this.post(
//     `/retailers/me/size_charts/${id}`,
//     body
//   );
//   return response;
// }

// addGenericSizeChartToRetailer = async (body) => {
//   let response = await this.post(
//       `/retailers/me/size_charts/templates`,
//       body
//     );
//     return response;
// };
// plansRedirection = async (body) => {
//   let response = await this.post(
//       `/retailers/me/payments`,
//       body
//     );
//     return response;
// };


// getRetailerProfile = async () => {
//   let response = await this.get(
//       `/retailers/me`,
//   );
//   return response;
// };


// signupRetailer = async(params) => {
//   let response;
//   try {
//     response = await this.post(`/retailers`, params);
//   } catch (error) {
//     response = error;
//   }
//   return response;
// }

// getRetailerMeta = async(token) => {
//   let response = await this.get(`/retailers/meta`);
//   return response;
// }

// externalSignIn = async(email, password) => {
//   const headers = {...this.headers};
//   headers.Email = email;
//   headers.Password = password;
//   this.http.headers = headers;
//   let response;
//   try {
//      response = await this.get(`/retailers/me`);

//   } catch (error) {
//     console.log(error);
//     response = error;
//   }

//   return response;
// }
// resetPassword = async(email) => {
//   const response = await this.post("/retailers/reset_password", {email});
//   return response;
// }