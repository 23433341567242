import { useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { context } from "../../App";
import {QRCodeSVG} from 'qrcode.react';
import { apiMeasuremenets } from "./../../utils/api-measurements"

export const Qrcode = ({enlargeQrCode}) => {
    const userContext = useContext(context);
    let navigate = useNavigate();


    const svgFromApi = `https://api.mysz.io/qr?token=${userContext.data?.user?.token}&format=svg`;

    const imgRef = useRef()

    useEffect(() => {
        if (!userContext.data?.user?.token) {
            // alert()
            // navigate('/');
        }


    }, [])

    const hideLoader = () => {
        imgRef.current.classList.remove('loading');
    }

    const getMeasurementsAsString = () => {
        const {height, weight, belly_shape, hip_shape, bra_band, bra_cup, bra_unit, body_type} = apiMeasuremenets(userContext.data.user.userInputs, true, true);
        const {gender, measurementSystem} = userContext.data.user.userInputs;
        return `${height},${weight},${gender},${Math.abs(measurementSystem - 1)},${belly_shape || 0},${hip_shape || 0},${bra_band || 0},${bra_cup || 0},${bra_unit || 0},${body_type || 0}`
    }

    return (
            <section className="qrcode loading" ref={imgRef} onClick={() => enlargeQrCode(imgRef)}>
                {/* <img src={svgFromApi} alt="QR Code" onLoad={hideLoader} onError={hideLoader}></img> */}
                <QRCodeSVG className="qrcode__img" size={40} value={getMeasurementsAsString()} onLoad={hideLoader} onError={hideLoader} alt="QR Code"/>
                <label><strong>Use with the <br/>“FirstLook” Smart Mirror</strong></label>
            </section>
          
    )
}