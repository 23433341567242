import React, { useEffect, useContext } from "react";
import SwitchButtonSmallCircle from "../SwitchButtons/SwitchButtonSmallCircle/SwitchButtonSmallCircle";
import SwitchButtonBigCircle from "../SwitchButtons/SwitchButtonBigCircle/SwitchButtonBigCircle";
import logo from "../../assets/images/size-new-logo.png";
import "./BasicInfo.scss";
import { context } from "../../App";
import PAGE_STATES from "../../utils/stateNames.js";
import { useSearchParams } from "react-router-dom";
import {CircularProgress} from "@material-ui/core";



export default function BasicInfo({
  measurementSystem,
  setMeasurementSystem,
  feet,
  setFeet,
  inches,
  setInches,
  height,
  setHeight,
  weight,
  setWeight,
  gender,
  setGender,
  weightValid,
  isDirty,
  setIsDirty,
  heightValid,
  errors,
  showBodyShapes,
  setPageState,
  onSubmitHandler,
  showLoader,
  email,
  setEmail
}) {

  const userContext = useContext(context);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const defaultGender = searchParams.get("gender");
    defaultGender && setGender(parseInt(defaultGender));
  }, []);

  const showGenderSelection = searchParams.get("gender") ? false : true;

  const onKeyDownHandler = (e) => {
    return (e.key !== "0" &&
      e.key !== "1" &&
      e.key !== "2" &&
      e.key !== "3" &&
      e.key !== "4" &&
      e.key !== "5" &&
      e.key !== "6" &&
      e.key !== "7" &&
      e.key !== "8" &&
      e.key !== "9" &&
      e.key !== "Backspace" &&
      e.key !== "Tab"
    ) &&
      e.preventDefault();
  }



  // if(!userContext.data?.meta) return <Loader/>
  return (
    <div className="basic-info">
      <div className="form-container">
        <div className="logo-frame">
          <img className="logo-image" src={logo} alt="MySizeID logo" />
            {userContext.data?.meta?.logo && 
            <>
              <div className="separator-line"></div>
              <img className="logo-image" src={userContext.data?.meta?.logo} alt="Retailer logo" />
            </>}
        </div>
        {userContext.data?.meta?.instore_show_email && <div className="form-email full-input">
          <div className="input-label">Email</div>
          <input
              onClick={(ev) => ev.target.select()}
              onChange={(e) => setEmail(e.target.value)}
              className="noSelect full-input"
              type="email"
              inputMode="email"
              placeholder="Email"
              value={email}
            />
        </div>}

        <div className="form-header">
          <div className="input-label">Height</div>
          <SwitchButtonSmallCircle
            setState={setMeasurementSystem}
            state={measurementSystem}
          >
            {["in", "cm"]}
          </SwitchButtonSmallCircle>
        </div>
        {measurementSystem === 0 ? (
          <div
            className={`input-height-inches${errors?.height ? " height-error" : ""
              }`}
          >
            <input
              onKeyDown={onKeyDownHandler}
              onClick={(ev) => ev.target.select()}
              onChange={(e) => {
                if (e.target.value < 8) {
                  setFeet(e.target.value );
                }
              }}
              className="noSelect"
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              id="input-height-feet"
              placeholder="Feet"
              min="0"
              maxLength={2}
              value={isDirty?.feet && feet > 0 ? Math.round(feet) : ""}
            />

            <input
              onKeyDown={onKeyDownHandler}
              onClick={(ev) => ev.target.select()}
              onChange={(e) => {
                if (+feet === 7) {
                  if (e.target.value < 12) {
                    setInches(e.target.value);
                  }
                } else {
                  if (e.target.value < 13) {
                    setInches(e.target.value);
                  }
                }
              }}
              className="noSelect"
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              id="input-height-inches"
              placeholder="Inches"
              min="0"
              maxLength={2}
              value={isDirty?.inch && inches > 0 ? Math.round(inches) : feet > 0 ? Math.round(inches) : ''}
            />
          </div>
        ) : (
          <input
            onKeyDown={onKeyDownHandler}
            onClick={(ev) => ev.target.select()}
            onChange={(e) => {
              setHeight(+e.target.value);
            }}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            className="full-input noSelect"
            placeholder="Height"
            id="input-height"
            min="0"
            maxLength={3}
            value={+height !== 0 ? Math.round(height) : ""}
          />
        )}
        {!heightValid ? (
          ""
        ) : (
          <p className="validation-error">Please enter height</p>
        )}
        <div className="form-header">
          <div className="input-label">Weight</div>
          <SwitchButtonSmallCircle
            setState={setMeasurementSystem}
            state={measurementSystem}
          >
            {["lb", "kg"]}
          </SwitchButtonSmallCircle>
        </div>
        <input
          onKeyDown={onKeyDownHandler}
          onClick={(ev) => ev.target.value && ev.target.select()}
          onChange={(e) => {
               setWeight(+e.target.value);
          }}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          className="full-input noSelect"
          id="input-height"
          min="0"
          maxLength={3}
          placeholder="Weight"
          value={isDirty?.weight && +weight > 0 ? Math.round(weight) : ""}
        />
        {!weightValid ? (
          ""
        ) : (
          <p className="validation-error">Please enter weight</p>
        )}
        {showGenderSelection && <React.Fragment>
          <div className="form-header">
            <div className="input-label">Gender</div>
          </div>
          <SwitchButtonBigCircle setState={setGender} state={gender}>
            {["Male", "Female"]}
          </SwitchButtonBigCircle>
        </React.Fragment>}
        <button
          className={`submit-button noSelect${errors.msg !== '' ? ' error' : ''}`}
          type="button"
          onClick={(e) => {
            if (errors.msg !== "") {
              e.target.classList.add('clicked')
            } else {
              if (showBodyShapes())
                setPageState(PAGE_STATES.ADVANCED_INFO_PAGE);
              else {
                onSubmitHandler()
              }
            }
          }}
        >
          {showLoader ? (
            <div>
              <CircularProgress
                size={30}
                thickness={6}
                style={{ color: "white" }}
              />
            </div>
          ) : (
            "Next"
          )}
        </button>
        <label className={`error-msg${errors.msg ? " shown" : ""}`}>
          {errors.msg}
        </label>
      </div>
    </div>
  );
}
