import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { context } from '../../App';
import MySizeApiService from "../../services/MySizeApiService";
import './Scan.scss';
import Logo from '../../assets/images/size-new-logo.png';
import Ruler from '../../assets/images/ruler-icon.png';
import Tag from '../../assets/images/tag-icon.png';
import Redo from '../../assets/images/redo-icon-gray.svg';
import ArrowLeft from '../../assets/images/arrow-left-gray.svg';
import MultiSizes from '../MultiSizes/MultiSizes';
import StorageService from '../../services/StorageService';
import PAGE_STATES from "../../utils/stateNames.js";


import { Qrcode } from './Qrcode';
import { Loader } from '../Loader/Loader';

export const Scan = ({setPageState, resetForm}) => {
    const userContext = useContext(context)
    let navigate = useNavigate();
    const userToken = userContext.data?.user?.token;
    const instantAppApiLink = `https://i.mysz.io/`;

    const [instantAppLink, setInstantAppLink] = useState('')
    const [isLoading, setIsLoading] = useState(true);

    const showQRCode = () => !StorageService.sessionLoad(userContext.data?.INSTORE_NAME_KEY) || userContext.data?.meta?.instore_show_profile_qr
    const showOpenScannerButton = () => StorageService.sessionLoad(userContext.data?.INSTORE_NAME_KEY) && userContext.data?.meta?.instore_show_scan
    const showOpenInstantAppButton = () => !StorageService.sessionLoad(userContext.data?.INSTORE_NAME_KEY) || userContext.data?.meta?.instore_show_instant_app

    const areArraysEqual = (arr1, arr2) => {
        return Array.isArray(arr1) &&
                Array.isArray(arr2) &&
                arr1.length === arr2.length &&
                arr1.every((val, index) => val === arr2[index]);
    }

    useEffect(() => {
        // load the data session
        init();
    }, [])

    window.areArraysEqual = areArraysEqual;

    const init = () => {
        if (areArraysEqual(Object.keys(userContext.data), ['INSTORE_NAME_KEY', 'CONTEXT_DATA_KEY'])) {
            userContext.setData(JSON.parse(StorageService.sessionLoad(userContext.data.CONTEXT_DATA_KEY)).data);
        }

        if (!userContext.data?.meta?.instore_multi_product_mode) {
            userContext.origin = '/scan';
            userContext.action = null;
            userContext.lastBarcode = {}
            userContext.barcode = {}

            generateInstantAppLink()
        } else {
            setIsLoading(false)
        }
    }

    const generateInstantAppLink = async () => {
        // const instantAppShortLink = await MySizeApiService.getInstantAppShortLink(instantAppApiLink);
        // setInstantAppLink(instantAppShortLink.url)
        setIsLoading(false)
    }

    const enlargeQrCode = (ev) => {
        ev.current.classList.toggle('show')
    }

    const renderPage = (isMultiProductModeEnabled) => {
        if (isMultiProductModeEnabled) {
            return <section>
                {isLoading && <Loader />}
                <MultiSizes />
            </section>
        }
        return <section className="scan">
            {isLoading && <Loader />}

            <img className="logo" src={Logo} alt="Logo" />

            {showQRCode() && <div className="qr-code">
                <Qrcode enlargeQrCode={enlargeQrCode} />
                <div className="heading">
                    <h4>Sizing Profile</h4>
                    <h6>Use with the “FirstLook” <br/> Smart Mirror</h6>
                </div>
            </div>}

            <div className="white-box">
                {(showOpenInstantAppButton() || 
                showOpenScannerButton()) && 
                    <div className="content">
                    <h4 className="more-actions-title">More Actions</h4>
                    {showOpenScannerButton() && 
                        <div className="scanner-box action-box">
                            <button onClick={() => { 
                                    navigate('/scanner') 
                                }}>
                                <div className="circle">
                                    <img src={Tag} alt="Tag Icon" />
                                </div>
                                <div className="heading">
                                    <h5>Scan An Item</h5>
                                    <h6>To see your recommended size</h6>
                                </div>
                                <img src={ArrowLeft} alt="arrow" />
                            </button>
                        </div>
                    }

                    {showOpenInstantAppButton() && <div className="instant-app action-box">
                        <a href={instantAppApiLink} target="_blank" rel="noreferrer">
                            <div className="circle">
                                <img src={Ruler} alt="Ruler Icon" />
                            </div>
                            <div className="heading">
                                <h5>Open Measurement App</h5>
                                <h6>For a more accurate size</h6>
                            </div>
                            <img src={ArrowLeft} alt="arrow" />
                        </a>
                    </div>}
                </div>}
            </div>
        </section >
    }

    const getUrlPostfix = () => {
        return StorageService.sessionLoad(userContext?.data.INSTORE_NAME_KEY) ? 
                `/?r=${StorageService.sessionLoad(userContext?.data.INSTORE_NAME_KEY)}` :
                "/"
    }

    const startOverHandler = () => {

        if(setPageState){
            resetForm();
            setPageState(PAGE_STATES.BASIC_INFO_PAGE);

        } 
        navigate(getUrlPostfix())

    }

    return <>
        {renderPage(userContext.data?.meta?.instore_multi_product_mode)}
        <div className="start-over">
            <button onClick={() => { startOverHandler() }}><img src={Redo} alt="Start Over" />Start Over</button>
        </div>
    </>
}