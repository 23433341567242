import React, { useEffect, useState, useContext } from "react";
import { context } from "../../App";
import MySizeApiService from "../../services/MySizeApiService";
import MultiResultCategory from "../MultiResultCategory/MultiResultCategory";
import "./MultiSizes.scss";
import StorageService from "../../services/StorageService";
import { apiMeasuremenets } from '../../utils/api-measurements'


export default function MultiSizes() {
  const userContext = useContext(context);
  console.log("sergey",userContext)
  const [sizes, setSizes] = useState([]);

  let categoriesMap = [];

  useEffect(() => {
    getSizes();
  }, []);

  const updateSize = (categories, targetProduct, size) => {
    let finished = false;
    for (const category of categories) {
      for (const product of category.products) {
        if (JSON.stringify(product) === JSON.stringify(targetProduct)) { // found targetProduct
          if (size?.name) {
            targetProduct.size = size.name;
          } else {
            targetProduct.size = '-';
          }
          finished = true;
        }
      }
      if (finished) {
        break;
      }
    }
  }

  const jsonToFormData = (data, formData = new FormData(), parentKey, isMain = true) => {
    let idx = Array.isArray(data) ? data.length - 1 : undefined;
    if (data && typeof data === 'object' && !Array.isArray(data) && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key, index) => {
            idx = index;
            jsonToFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key, false);
        });
    } else {
        const value = data == null ? '' : data;

        if (Array.isArray(value)) {
            value.forEach(val => formData.append(`${parentKey || '0'}[]`, val));
        } else {
            value && formData.append(parentKey || '0', value);
        }
    }
    if (isMain && data && idx === ((typeof data === 'object' && !Array.isArray(data)) ? Object.keys(data).length - 1 : (Array.isArray(data) ? data.length - 1 : undefined))) return formData
}



  const getSizes = async () => {
    let categories = await MySizeApiService.getAllStoreCategories(
      userContext.data?.user.userInputs.gender === 0 ? "male" : "female",
      StorageService.sessionLoad(userContext.data?.INSTORE_NAME_KEY),
      userContext.data?.meta?.instore_auth_code
    );
    
    if (!categories.code) {
      categoriesMap = [...categories];
      setSizes(categoriesMap);
      const haveBodyShapes = !StorageService.sessionLoad(userContext.data?.INSTORE_NAME_KEY) || userContext.data?.meta?.instore_show_body_shapes;  
      categories.forEach((category) => {
        // const measurements = {};
        // [...userContext.data.user.measurements].forEach(m => measurements[m.measurement_type.name] = m.value)
        category.products.forEach((product) =>
          new Promise((resolve) => {
            const garmetSizeParams = {
              measurement_system: userContext.data.user.userInputs.measurementSystem === 0 ? "imperial" : "metric",
              retailer_token: userContext.data.meta.shop_id,
              gender: userContext.data.user.userInputs.gender ? "female" : "male",
              measurements: apiMeasuremenets(userContext.data.user.userInputs, true, haveBodyShapes),
              size_chart_code: product.integration_code,
              customData: {userEmail: userContext.data.user.userInputs.email},
              url: `wiz://${userContext.data?.meta?.instore_auth_code}/${product.id}`

            }
            MySizeApiService.getGarmentSizeServerless(new URLSearchParams(jsonToFormData(garmetSizeParams))).then(size => size.json()).then((size) => {
              console.log('categoriesMap before => ', categoriesMap);
              size.name = size.size;   // FOR API2
              updateSize(categoriesMap, product, size);
              console.log('categoriesMap after => ', categoriesMap);
              categoriesMap = [...categoriesMap];
              setSizes(categoriesMap);
              resolve(product);
            });
          })
        );
      });


    }
  };

 
  return (
    <div className="content">
      {sizes ? (
        <>
          <div className="title-wrapper">
            <h1 className="title">Hey, your sizes are:</h1>
          </div>
          <div className="table">
            <MultiResultCategory categories={sizes} />
          </div>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}
