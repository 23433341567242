import React, { useState,useEffect } from 'react';
import PAGE_STATES from '../../../utils/stateNames';


import './SwitchButton.css';

function SwitchButton({setState ,state=null,isSpaceBetween, isCenter, children, rendererComponent}){    
    const [selected, setSelected] = useState(state); 
    // const location = useLocation();
    useEffect(() => {
        if (setState)
        setState(selected);
      }, [selected, setState]);   


    useEffect(() => {    
        setSelected(state);
    }, [state])

    

    let switchButtonStyle = "switch-button";
    let activeStyle = "active";

    if(rendererComponent === PAGE_STATES.VISUALISATION_PAGE){
        switchButtonStyle = "switch-button__white";
        activeStyle = "active__white";
    }
   

    return(                     
        <div className={`switch-container noSelect${isSpaceBetween?' space-between':''}`}>
        {
            children.map((child,index) => {      
                
                    return( 
                    <div key={index} className={`${switchButtonStyle} ${selected===index ? activeStyle : ''}${isCenter&&index===1?' center':''}`} onClick={() => setSelected(index)}>
                       {child}
                    </div>
                    );
                 
            })            
        }
        </div>    
    )    
}
export default SwitchButton;
