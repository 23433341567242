import React from "react";
import SwitchButtonImg from "../SwitchButtons/SwitchButtonImg/SwitchButtonImg";
import maleFlatter from "../../assets/images/male-flatter.webp";
import maleAverage from "../../assets/images/male-average.webp";
import maleRounder from "../../assets/images/male-rounder.webp";
import femaleFlatter from "../../assets/images/female-flatter.webp";
import femaleAverage from "../../assets/images/female-average.webp";
import femaleRegular from "../../assets/images/female-regular.webp";
import femaleCurvey from "../../assets/images/female-curvey.webp";
import SwitchButtonSmallCircle from "../SwitchButtons/SwitchButtonSmallCircle/SwitchButtonSmallCircle";
import "./AdvanceInfo.scss";
import bodyTypes from './../../assets/bodyTypes';
import {euBandArr, euCupArr, usBandArr, usCupArr} from './../../utils/bra-sizes';
import SwitchButtonBigCircle from "../SwitchButtons/SwitchButtonBigCircle/SwitchButtonBigCircle";


import {
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

export default function AdvanceInfo({
  setBellyShape,
  bellyShape,
  gender,
  hipShape,
  setHipShape,
  radioBtn,
  setRadioBtn,
  braUnit,
  setBraUnit,
  braBand,
  setbBraBand,
  braCup,
  setbBraCup,
  errors,
  onSubmitHandler,
  showLoader,
}) {
  let currentBand = null;
  let currentCup = null;

  if (braUnit === 1) {
    currentBand = euBandArr;
    currentCup = euCupArr;
  } else if (braUnit === 0) {
    currentBand = usBandArr;
    currentCup = usCupArr;
  }

  const isBodyTypesSelected = () => {
    if(bellyShape === null) return false;
    if(gender === 1 && hipShape === null) return false;
    return true;
  }

  return (
    <div className="advance-info">
      <div className={`select-container ${gender === 0 && "male-container"}`}>
        {gender === 1 && (
          <div className="female-section">
            <p>Do you know your bra size?</p>
            {/* <div className="radio-btns">
              <div
                className="input-radio-btn"
                onClick={() => {
                  setRadioBtn("No");
                }}
              >
                <input
                  type="radio"
                  value={radioBtn}
                  name="No"
                  checked={radioBtn === "No"}
                  readOnly
                />
                <span>Not sure</span>
              </div>
              <div
                className="input-radio-btn"
                onClick={() => {
                  setRadioBtn("Yes");
                }}
              >
                <input
                  type="radio"
                  value={radioBtn}
                  name="Yes"
                  checked={radioBtn === "Yes"}
                  readOnly
                />
                <span>Yes</span>
              </div>
            </div> */}
            {/* <FormControl component="fieldset" className="noSelect"> */}
            
            <div className="female-section__question-container">
              <SwitchButtonBigCircle setState={setRadioBtn} state={radioBtn}>
                  {["Not Sure", "Yes"]}
              </SwitchButtonBigCircle>
            </div>

            {/* </FormControl> */}
            {radioBtn === 0 ? (
              ""
            ) : (
              <div className="bra-container">
                <div className="header">
                  <div className="title">Bra Size</div>
                  <div className="bra__unit">
                    <SwitchButtonSmallCircle
                      setState={setBraUnit}
                      state={braUnit}
                    >
                      {["US", "EU"]}
                    </SwitchButtonSmallCircle>
                  </div>
                </div>
                <div className="selectors">
                  {currentBand && (
                    <select
                      className="select-input"
                      name="brands"
                      value={braBand}
                      onChange={(e) => setbBraBand(e.target.value)}
                    >
                      {currentBand.map((item, index) => {
                        return (
                          <option key={index + "band"} value={index}>
                            {item}
                          </option>
                        );
                      })}
                      ]
                    </select>
                  )}
                  {currentCup && (
                    <select
                      className="select-input"
                      name="cups"
                      value={braCup}
                      onChange={(e) => setbBraCup(e.target.value)}
                    >
                      {currentCup.map((item, index) => {
                        return (
                          <option key={index + "cup"} value={index}>
                            {item}
                          </option>
                        );
                      })}
                      ]
                    </select>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <div className="belly-container">
          <p>Select your belly shape</p>
          <SwitchButtonImg
            setState={setBellyShape}
            state={bellyShape}
            isSpaceBetween
          >
            {[
              {
                title: "Flatter",
                src: gender === 0 ? bodyTypes.maleFlatter : bodyTypes.femaleFlatter,
              },
              {
                title: "Average",
                src: gender === 0 ? bodyTypes.maleAverage : bodyTypes.femaleAverage,
              },
              {
                title: "Rounder",
                src: gender === 0 ? bodyTypes.maleRounder : bodyTypes.femaleAverage,
              },
            ]}
          </SwitchButtonImg>
        </div>

        {gender === 1 && (
          <div className="hip-container">
            <div className="select-container-label">Select your hip shape</div>
            <SwitchButtonImg setState={setHipShape} state={hipShape} isCenter>
              {[
                { title: "Average", src: bodyTypes.femaleHipsAverage },
                { title: "Rounder", src: bodyTypes.femaleHipsRounder },
              ]}
            </SwitchButtonImg>
          </div>
        )}

        <button
          className={`submit-button noSelect ${showLoader && "unclickable"}`}
          type="button"
          disabled={errors.msg !== "" || !isBodyTypesSelected() }
          onClick={(e) => {
            onSubmitHandler();
          }}
        >
          {showLoader ? (
            <div>
              <CircularProgress
                size={30}
                thickness={6}
                style={{ color: "white" }}
              />
            </div>
          ) : (
            "Next"
          )}
        </button>
        <label className={`error-msg${errors.msg ? " shown" : ""}`}>
          {errors.msg}
        </label>
      </div>
    </div>
  );
}
