import Webcam from "react-webcam";

export const Test = () => {
    const videoConstraints = {
        width: 300,
        height: 300,
        facingMode: "user"
      };

    const onError = (err) => {
        console.log(err);
    }
    return (
        <>
        <h1>Test</h1>
        <Webcam
        //   width="100%"
        //   height="100%"
        //   ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          audio={false}
          onUserMediaError={onError}
          screenshotQuality='1'
        />
        </>
    )
}