import {euBandArr} from './bra-sizes';


const getBodyType = (gender, hipShape, bellyShape) => {
  if(gender === 1){
    if(((bellyShape === 1 && hipShape === 1) || ( hipShape === 1 && bellyShape === 2))) return 1;
    if((hipShape === 2 && bellyShape === 1 ) || (hipShape === 2 && bellyShape === 2 ) || (hipShape === 2 && bellyShape === 3)) return 2;
    if(hipShape === 1 && bellyShape === 3) return 3;
    if(hipShape === 0 && bellyShape === 0) return 1;
  }
  else if(gender === 0){
    if(bellyShape === 3) return 3;
    if(bellyShape === 2 || bellyShape === 0) return 1;
    if(bellyShape === 1) return 2;
  }
}



const apiMeasuremenets = (user, keepMeasurementSyste = false, haveBodyShapes) => {

    // bra_unit (1)metric,(2)imperial
    // bra_cup [1..16]
    // belly_shape [1..3]
    // hip_shape [1,2]
    const measurements  = user.measurements;   
    const gender  = user.gender;   
    const measurementSystem  = user.measurementSystem; 


    let calcualtedBraBand;
    let height;
    let weight;

    if(measurementSystem === 1) {
      calcualtedBraBand = 60 + (+measurements.braBand * 5);
      height = +measurements.height;
      weight = +measurements.weight;
    }
    if(measurementSystem === 0){
      if(keepMeasurementSyste){
        height = (+measurements.inches + (+measurements.feet * 12)).toFixed(2);
        weight = (+measurements.weight).toFixed(2)
      }else{
        height = ((+measurements.inches + (+measurements.feet * 12)) * 2.54).toFixed(2);
        weight = +measurements.weight * 0.453592
      }
      calcualtedBraBand = (euBandArr[measurements.braBand] / 2.54).toFixed(2);
    };
    let bra_band = +gender === 0 ? undefined : +measurements.braBand === 0 ? 999 : calcualtedBraBand;
    let bra_cup = +gender === 0 ? undefined : +measurements.braCup === 0 ? 999 : +measurements.braCup;
    let bra_unit = +gender === 0 ? undefined : bra_cup === 999 ? 999 : +measurements.braUnit === 0 ? 1 : 2;
    let hip_shape = +gender === 0 ? undefined : +measurements.hipShape + 1;
    let belly_shape = +measurements.bellyShape + 1;
    
    return {
      height,
      weight,
      ...(haveBodyShapes && {bra_band}),
      ...(haveBodyShapes && {bra_cup}),
      ...(haveBodyShapes && {bra_unit}),
      ...(haveBodyShapes && {hip_shape}),
      ...(haveBodyShapes && {belly_shape}),
      body_type: haveBodyShapes ? getBodyType(gender,hip_shape, belly_shape) : undefined
    }
  }


  const jsonToFormData = (data, formData = new FormData(), parentKey, isMain = true) => {
    let idx = Array.isArray(data) ? data.length - 1 : undefined;
    if (data && typeof data === 'object' && !Array.isArray(data) && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key, index) => {
            idx = index;
            jsonToFormData(data[key], formData, parentKey ? `${parentKey}[${key}]` : key, false);
        });
    } else {
        const value = data == null ? '' : data;

        if (Array.isArray(value)) {
            value.forEach(val => formData.append(`${parentKey || '0'}[]`, val));
        } else {
            value && formData.append(parentKey || '0', value);
        }
    }
    if (isMain && data && idx === ((typeof data === 'object' && !Array.isArray(data)) ? Object.keys(data).length - 1 : (Array.isArray(data) ? data.length - 1 : undefined))) return formData
}

  export{apiMeasuremenets, jsonToFormData}