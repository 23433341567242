import { initializeApp } from "firebase/app";
import * as Config from '../config';

import {
  getAuth,
  signInAnonymously,
  inMemoryPersistence,
  initializeAuth,
  signOut
} from "firebase/auth";

const {
  API_KEY,
  AUTH_DOMAIN,
  DATABASE_URL,
  PROJECT_ID,
  STORAGE_BUCKET,
  MESSAGING_SENDER_ID,
  APP_ID,
  MEASUREMENT_ID,
} = Config[process.env.REACT_APP_ENV];

export default class FirebaseService {
  constructor() {
    this.auth = this.initialize();
  }

  initialize = () => {
    const config = initializeApp({
      apiKey: API_KEY,
      authDomain: AUTH_DOMAIN,
      databaseURL: DATABASE_URL,
      projectId: PROJECT_ID,
      storageBucket: STORAGE_BUCKET,
      messagingSenderId: MESSAGING_SENDER_ID,
      appId: APP_ID,
      measurementId: MEASUREMENT_ID,
    });
    
    // Hack for privacy mode
    try {
      window.localStorage.setItem('mySize-FB', Date.now());
      window.localStorage.removeItem('mySize-FB');
      return getAuth(config);
    } catch (e) {
      const initAuth = initializeAuth(config, { persistence: [inMemoryPersistence] });
      return initAuth;
    }
  };

  updateUserToken = async (user) => {
    const userInfo = {};
    if (user) {
      let isGuest = user.isAnonymous;
      await user
        .getIdToken()
        .then((idToken) => {
          userInfo.token = idToken;
          userInfo.isGuest = isGuest;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return userInfo;
  };

  signUpGuestUser = async () => {
    let userInfo = null;
    try {
      await signInAnonymously(this.auth).then(async (res) => {
        userInfo = await this.updateUserToken(res.user);
      });
    } catch (e) { 
      return { 
        errorMessage: e.code 
      }; 
    }
    return userInfo;
  };

  signOutUser = async () => {
    signOut(this.auth);
  }
}