import React, {useState, useEffect, Fragment} from 'react';
import SwitchButton from '../Base/SwitchButton';
import './SwitchButtonImg.css'

function SwitchButtonImg({setState ,state=null, isSpaceBetween=false, isCenter=false, children}){    
    return(    
        <div className={`switch-button-img noSelect`}>                 
            <SwitchButton setState={setState} state={state} isSpaceBetween={isSpaceBetween?true:false} isCenter={isCenter?true:false}>
                {
                    children.map((child,index) => {                
                        return(
                        <div className='switch-img-container' key={index} >
                            <img src={child.src} alt={"image"+child.src}></img>
                            <div>{child.title}</div>
                        </div>
                        );
                    })   
                }
            </SwitchButton>
        </div>
    )    
}
export default SwitchButtonImg;
